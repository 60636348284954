<template>
	<div>
		<v-row no-gutters class="px-3">
			<v-col xs12 :md6="!showAdvancedDate">
				<v-select
					:label="$t('aqi.dateInterval')"
					toplabel
					required
					:disabled="form.formDisabled"
					v-model="form.dateInterval"
					:items="form.intervals"
					@input="submitDateInterval"
					class="form-control-select mt-2 px-1"
				></v-select>
				<!-- @input="getPollutantChart" -->
			</v-col>
			<v-col v-if="showAdvancedDate" xs12 :md4="!form.showChartOptions" :md3="form.showChartOptions">
				<pui-date-field
					:label="$t('aqi.startDate')"
					toplabel
					:required="!form.startDateDisabled"
					v-model="form.startDate"
					:disabled="form.startDateDisabled"
					:max="form.endDate ?? Date.now()"
					:tooltipDescription="form.infoStartDate"
					@input="inputStartDate"
				></pui-date-field>
			</v-col>
			<v-col v-if="showAdvancedDate" xs12 :md4="!form.showChartOptions" :md3="form.showChartOptions">
				<pui-date-field
					:label="$t('aqi.endDate')"
					toplabel
					:required="!form.endDateDisabled"
					v-model="form.endDate"
					:disabled="form.endDateDisabled"
					:min="form.startDate"
					:max="Date.now()"
					:tooltipDescription="form.infoEndDate"
					@input="submitEndDate"
				></pui-date-field>
			</v-col>
		</v-row>
		<v-data-table
			:headers="elasticItems ? getHeaders() : []"
			:items="elasticItems ? elasticItems : []"
			:single-expand="singleExpand"
			:expanded.sync="expanded"
			item-key="id"
			show-expand
			:show-select="false"
			:items-per-page="10"
			class="elevation-1 pa-2"
			style="overflow-y: scroll !important"
			:loading="loadingAnimation"
			loading-text="Loading..."
			@click:row="expandRow"
			:footer-props="{
				showFirstLastPage: true,
				showCurrentPage: true,
				firstIcon: 'fak fa-line-start',
				lastIcon: 'fak fa-line-final',
				prevIcon: 'fak fa-line-back',
				nextIcon: 'fak fa-line-next',
				itemsPerPageOptions: [10, 25, 50, 100, -1]
			}"
		>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" class="px-1" v-if="item['id'] && item['emissions']">
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="fw-bold">{{ $t('grid.bpaposidoniawebtraffic.emissions.50Percent') }}</v-list-item-title>
							<v-data-table
								:headers="getElasticHeaders(item['emissions']['50PercentOfPower_Emissions'])"
								:items="[item['emissions']['50PercentOfPower_Emissions']]"
								hide-default-footer
								class="elevation-1 my-1"
								style="width: 100%"
							>
								<template v-slot:item.mainEnginePowerFactor="{ item }">
									<span class="fw-500">
										{{ item.mainEnginePowerFactor /* + ' kWh' */ }}
									</span>
								</template>
								<template v-slot:item.auxEnginePowerFactor="{ item }">
									<span class="fw-500">
										{{ item.auxEnginePowerFactor /* + ' kWh' */ }}
									</span>
								</template>
								<template v-slot:item.mainEngineEnergyUsed="{ item }">
									<span class="fw-500">
										{{ item.mainEngineEnergyUsed.toFixed(2) + ' kWh' }}
									</span>
								</template>
								<template v-slot:item.auxEngineEnergyUsed="{ item }">
									<span class="fw-500">
										{{ item.auxEngineEnergyUsed.toFixed(2) + ' kWh' }}
									</span>
								</template>
								<template v-slot:item.mainEngineEmissions="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.mainEngineEmissions) }}
									</span>
								</template>
								<template v-slot:item.auxEngineEmissions="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.auxEngineEmissions) }}
									</span>
								</template>
								<template v-slot:item.totalEmissions="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.totalEmissions) }}
									</span>
								</template>
								<template v-slot:item.emissionsPerPassenger="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.emissionsPerPassenger) }}
									</span>
								</template>
							</v-data-table>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="fw-bold">{{ $t('grid.bpaposidoniawebtraffic.emissions.100Percent') }}</v-list-item-title>
							<v-data-table
								:headers="getElasticHeaders(item['emissions']['100PercentOfPower_Emissions'])"
								:items="[item['emissions']['100PercentOfPower_Emissions']]"
								hide-default-footer
								class="elevation-1 my-1"
								style="width: 100%"
							>
								<template v-slot:item.mainEnginePowerFactor="{ item }">
									<span class="fw-500">
										{{ item.mainEnginePowerFactor /* + ' kWh' */ }}
									</span>
								</template>
								<template v-slot:item.auxEnginePowerFactor="{ item }">
									<span class="fw-500">
										{{ item.auxEnginePowerFactor /* + ' kWh' */ }}
									</span>
								</template>
								<template v-slot:item.mainEngineEnergyUsed="{ item }">
									<span class="fw-500">
										{{ item.mainEngineEnergyUsed.toFixed(2) + ' kWh' }}
									</span>
								</template>
								<template v-slot:item.auxEngineEnergyUsed="{ item }">
									<span class="fw-500">
										{{ item.auxEngineEnergyUsed.toFixed(2) + ' kWh' }}
									</span>
								</template>
								<template v-slot:item.mainEngineEmissions="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.mainEngineEmissions) }}
									</span>
								</template>
								<template v-slot:item.auxEngineEmissions="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.auxEngineEmissions) }}
									</span>
								</template>
								<template v-slot:item.totalEmissions="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.totalEmissions) }}
									</span>
								</template>
								<template v-slot:item.emissionsPerPassenger="{ item }">
									<span class="fw-500">
										{{ getEmissionsUnits(item.emissionsPerPassenger) }}
									</span>
								</template>
							</v-data-table>
						</v-list-item-content>
					</v-list-item>
				</td>
				<td :colspan="headers.length" class="px-1" v-else>
					<div class="d-flex flex-column align-center">
						<span>{{ $t('common.noEmissionData') }}</span>
					</div>
				</td>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';
import ViewsMixin from '@/mixins/ViewsMixin';

import { lastData /* getElasticDocument */ } from '@/api/databases_API';
import { /* query_getAllDataFreus, */ query_byDateRangeFreus } from '@/utils/queries.js';

export default {
	name: 'BpaPosidoniaWebTrafficGrid',
	components: {},
	mixins: [QueriesSQLMixin, ViewsMixin],
	props: {},
	data() {
		return {
			indexName: 'sp-bpa-posidoniaweb-traffic',
			expanded: [],
			singleExpand: false,
			elasticItems: null,
			loadingAnimation: true,
			elasticDetail: {},
			elasticDetailRounded: {},
			showAdvancedDate: false,
			form: {
				formDisabled: false,
				dateInterval: null,
				intervals: [
					/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
					{ text: this.$t('aqi.lastWeek'), value: 7 },
					{ text: this.$t('aqi.last15D'), value: 15 },
					{ text: this.$t('aqi.lastMonth'), value: 30 },
					{ text: this.$t('aqi.customInterval'), value: -1 }
				],
				startDate: null,
				endDate: null,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled')
			}
		};
	},
	computed: {
		deviceParameters() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		}
	},
	watch: {
		elasticItems: {
			handler(newValue, oldValue) {
				console.debug('elastic items watcher', oldValue, newValue);
			}
		},
		'form.dateInterval': {
			handler(newValue, oldValue) {
				console.debug('date interval watcher', oldValue, newValue);
				if (!newValue) {
					this.loadingAnimation = false;
					this.elasticItems = null;
					this.showAdvancedDate = false;
				}
			}
		},
		'form.startDate': {
			handler(newValue, oldValue) {
				console.info('startDate watcher', oldValue, newValue);
			}
		}
	},
	created() {},
	mounted() {
		this.form.dateInterval = 30;
		this.submitDateInterval();
		/* lastData(this.indexName, query_getAllDataFreus()).then((result) => {
			console.log('debug traffic then', result);
			if (result.message.length > 0) {
				const mappedArray = result.message.map((item) => {
					return item['_source'];
				});
				this.elasticItems = mappedArray;
				this.loadingAnimation = false;
			}
		}); */
		console.info('info: ', this.elasticItems);
	},
	methods: {
		getHeaders() {
			//console.log('debug: getNoticationHeaders', this.elasticItems, Object.keys(this.elasticItems[0]));
			let notificationKeys = [
				'data-table-expand',
				'imo',
				'puerto',
				'nombreBuque',
				'codigoArancelario',
				'numPasajeros',
				'codigoTipoOp',
				'cifConsignatario',
				'numDeclaracion',
				'numEscala',
				'fechaInicioEscala',
				'fechaFinEscala'
			];

			const mappedArray = notificationKeys.map((key) => {
				return { text: this.$t('grid.bpaposidoniawebtraffic.' + key), value: key };
			});
			//console.log('debug: mapped array', notificationKeys, mappedArray);
			//mappedArray.push({ text: this.$t('panels.notifications.source'), value: 'data-table-expand' });

			return mappedArray;
		},

		getElasticHeaders(item) {
			//var details = [];
			//console.log('info: traffic item', item, Object.keys(item));

			/* this.deviceParameters.forEach((parameter) => {
				if (Object.hasOwn(obj, parameter.elasticacronym)) {
					details.push({
						text: parameter.name,
						value: parameter.elasticacronym
					});
				}
			}); */

			let elasticKeys = Object.keys(item);

			const mappedArray = elasticKeys.map((key) => {
				return { text: this.$t('grid.bpaposidoniawebtraffic.emissions.' + key), value: key };
			});
			//console.log('debug: getElasticHeaders', mappedArray);
			//this.getElasticData(notificationId);
			return mappedArray;
		},
		getElasticData(notificationId) {
			let elasticKeys = Object.keys(this.elasticDetail);
			//let elasticDetailRounded = {};
			let elasticDetailRounded = {};
			elasticKeys.forEach((key) => {
				console.log('test ', key, isNaN(parseInt(this.elasticDetail[key])));
				if (!isNaN(parseInt(this.elasticDetail[key]))) {
					elasticDetailRounded[key] =
						parseInt(this.elasticDetail[key]) == this.elasticDetail[key]
							? parseInt(this.elasticDetail[key])
							: parseFloat(this.elasticDetail[key]).toFixed(2);
				} else {
					elasticDetailRounded[key] = this.elasticDetail[key];
				}

				/* elasticDetailRounded.push({
					index: key,
					value:
						parseInt(this.elasticDetail[key]) == this.elasticDetail[key]
							? parseInt(this.elasticDetail[key])
							: parseFloat(this.elasticDetail[key]).toFixed(2)
				}); */
			});

			//return elasticDetailRounded;
			this.elasticDetailRounded[notificationId] = elasticDetailRounded;
			console.log('debug: getElasticData', notificationId, this.elasticDetailRounded);
		},

		setRoundElasticData(elasticSource, notificationId) {
			let elasticKeys = Object.keys(elasticSource);
			//let elasticDetailRounded = {};
			let elasticDetailRounded = {};
			elasticKeys.forEach((key) => {
				console.log('test ', key, isNaN(parseInt(elasticSource[key])));
				let parameter = this.deviceParameters.find((parameter) => parameter.elasticacronym == key);

				if (!isNaN(parseInt(elasticSource[key]))) {
					elasticDetailRounded[key] =
						parseInt(elasticSource[key]) == elasticSource[key] ? parseInt(elasticSource[key]) : parseFloat(elasticSource[key]).toFixed(2);
					if (parameter) elasticDetailRounded[key] += ' ' + parameter.symbol;
				} else {
					elasticDetailRounded[key] = this.elasticDetail[notificationId][key];
				}

				/* elasticDetailRounded.push({
					index: key,
					value:
						parseInt(this.elasticDetail[key]) == this.elasticDetail[key]
							? parseInt(this.elasticDetail[key])
							: parseFloat(this.elasticDetail[key]).toFixed(2)
				}); */
			});

			//return elasticDetailRounded;
			this.elasticDetailRounded[notificationId] = elasticDetailRounded;
			console.log('debug: getElasticData', elasticSource, notificationId, this.elasticDetailRounded);
		},
		expandRow(rowData) {
			if (this.expanded[0] == rowData) this.expanded = [];
			else this.expanded = [rowData];
		},
		getEmissionsUnits(value) {
			//console.log('get emissions units: ', value);
			let formattedValue = '';
			if (value) {
				formattedValue = (value * 1000).toFixed(2) + ' Kg NOx';
			}
			return formattedValue;
		},
		submitDateInterval() {
			var d = new Date();
			console.log('submitDateInteval', d, this.form.dateInterval);
			if (this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
				} else {
					this.loadingAnimation = true;
					this.showAdvancedDate = false;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					d.setDate(d.getDate() - this.form.dateInterval);
					this.elasticItems = null;
					lastData(this.indexName, query_byDateRangeFreus(d.valueOf(), Date.now())).then((elasticData) => {
						console.log('lastData result (dateInterval)', elasticData);
						console.log(query_byDateRangeFreus(d.valueOf(), Date.now()));
						if (elasticData.message.length > 0 && typeof elasticData.message !== 'string') {
							const mappedArray = elasticData.message.map((item) => {
								return item['_source'];
							});
							this.elasticItems = mappedArray;
						} else if (typeof elasticData.message == 'string') {
							this.$puiNotify.warning(elasticData.message, this.$t('status.warning'));
						} else {
							this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
						}
						this.loadingAnimation = false;
					});
				}
			} else {
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				console.warn(this.displayLocation + ' startDate input: ' + this.form.startDate);
				console.warn('startDate: ' + Date.parse(this.form.startDate));
				this.form.endDateDisabled = false;

				if (this.form.endDate) this.submitEndDate(); // better usability
			} else {
				this.form.endDateDisabled = true;
			}
			//this.form.endDate = null;
		},
		submitEndDate() {
			console.log('info: submit end date ', this.form.endDate);
			if (this.form.startDate && this.form.endDate) {
				this.loadingAnimation = true;
				this.elasticItems = null;
				let startDate = new Date(Date.parse(this.form.startDate)).addHours(2).valueOf();
				let endDate = new Date(Date.parse(this.form.endDate)).addHours(26).valueOf();
				lastData(this.indexName, query_byDateRangeFreus(startDate, endDate)).then((elasticData) => {
					console.debug('lastData result (endDate)', elasticData, this.form);
					console.info(
						query_byDateRangeFreus(startDate, endDate),
						new Date(Date.parse(this.form.startDate)).addHours(2),
						new Date(Date.parse(this.form.endDate)).addHours(26)
					);
					if (elasticData.message.length > 0 && typeof elasticData.message !== 'string') {
						const mappedArray = elasticData.message.map((item) => {
							return item['_source'];
						});
						this.elasticItems = mappedArray;
					} else {
						console.log('inserted null');
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
					}
					this.loadingAnimation = false;
				});
			}
		}
	}
};
</script>

<style lang="postcss">
.v-data-table {
	& tbody tr {
		cursor: pointer;
		/*&:hover {
			color: var(--azul-primary-100);
		}*/
	}
	& span {
		font-size: 1rem;
	}
	& td {
		font-size: 1rem;
	}

	& > .v-data-footer {
		.v-icon {
			font-size: 1.25rem !important;
		}
	}
}
</style>
